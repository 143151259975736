import request from "../utils/request";

export function getMyZhiboRooms(student_id, project_code) {
    return request.post('./RE_Arena/index.php', {
        function: 'getWAOExamRooms',
        student_id,
        project_code
    })
}

export function checkZhiboOpen(student_id, roomId, project_code, event) {
    return request.post('./RE_Arena/index.php', {
        function: 'checkZhiboOpen',
        student_id,
        roomId,
        project_code,
        event
    })
}

export function getExerciseTestTemplate(event, project_code, type, test_event, template_id) {
    return request.post('./RE_Arena/index.php', {
        function: 'getExerciseTestTemplate',
        event,
        project_code,
        type,
        test_event,
        template_id
    })
}


export function checkIsUploadTest(template_id, student_id) {
    return request.post('./RE_Arena/index.php', {
        function: 'checkIsUploadTest',
        template_id,
        student_id
    })
}


export function checkUserInvigilate(project_code, student_id) {
    return request.post('./RE_Arena/index.php', {
        function: 'checkUserInvigilate',
        project_code,
        student_id
    })
}

export function getExerciseTemplateByStudent(template_id, user_id) {
    return request.post('./RE_Arena/index.php', {
        function: 'getExerciseTemplateByStudent',
        template_id,
        user_id
    })
}

export function submitAnswer(question_id, template_id, choice, student_id, ip, test_event, project_code, answer_img_url) {
    return request.post('./RE_Arena/index.php', {
        function: 'submitAnswer',
        question_id,
        template_id,
        choice,
        student_id,
        ip,
        test_event,
        project_code,
        answer_img_url
    })
}

export function studentSignWithTemplate(student_id, template_id, project_code) {
    return request.post('./RE_Arena/index.php', {
        function: 'studentSignWithTemplate',
        student_id,
        template_id,
        project_code
    })
}

export function uploadAnswersV3(project_code, student_id, template_id, app_id, ip, test_event, test_type, answers) {
    return request.post('./RE_Arena/index.php', {
        function: 'uploadAnswersByLogsNew',
        project_code,
        student_id,
        template_id,
        app_id,
        ip,
        test_event,
        test_type,
        answers
    })
}

export function getGroupId(id) {
    return request.post("./RE_Arena/index.php", {
        function: "getGroupId",
        id
    });
}

export function getQiniuToken() {
    return request.post('./RE_Arena/index.php', {
        function: 'getQiniuToken'
    })
}

export function studentUpdateTemplateWithIndex(student_id, template_id, project_code, index) {
    return request.post('./RE_Arena/index.php', {
        function: 'studentUpdateTemplateWithIndex',
        student_id,
        template_id,
        project_code,
        index
    })
}

export function uploadPic(param) {
    return request.post('./RE_Arena/index.php', param)
}

export function postRequest(url, params) {
    return request.post(url, {
        ...params
    })
}

export function getStudentIdCard(student_id, template_id, project_code) {
    return request.post('./RE_Arena/index.php', {
        function: 'getStudentIdCard',
        student_id,
        template_id,
        project_code
    })
}

export function uploadStudentIdCard(student_id, template_id, project_code, pic_url) {
    return request.post('./RE_Arena/index.php', {
        function: 'uploadStudentIdCard',
        student_id,
        template_id,
        project_code,
        pic_url
    })
}


export function getResidueTime(project_code, student_id, event_name, videoTime) {
    return request.post('./RE_Arena/index.php', {
        function: 'getResidueTime',
        project_code,
        student_id,
        event_name,
        videoTime
    })
}

export function saveVideoRecordPlayTime(student_id, content_id, project_code, stamp) {
    return request.post("./RE_Arena/index.php", {
        function: "saveVideoRecordPlayTime",
        student_id,
        content_id,
        project_code,
        stamp
    });
}
export function getVideoRecordTime(student_id, content_id, project_code) {
    return request.post("./RE_Arena/index.php", {
        function: "getVideoRecordTime",
        student_id,
        content_id,
        project_code
    });
}
export function getLectureTip(student_id, project_code) {
    return request.post("./RE_Arena/index.php", {
        function: "getLectureTip",
        student_id,
        project_code
    });
}

export function getUserNames(project_code) {
    return request.post('./RE_Arena/index.php', {
        function: 'getUserNames',
        project_code
    })
}