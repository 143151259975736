<template>
  <div class="listContainer">
    <p class="font-bold text-lg">{{ project_name }}</p>

    <div v-for="(item, index) in event_rooms" :key="index" class="eventItem">
      <img :src="require('../../../assets/image/examBgList2.png')
        " class="eventImg2" />

      <img :src="require('../../../assets/image/examListBg3.png')
        " class="eventImg1" />
      <div class="left">
        <div class="itemTitle" :style="{ color: colorMap[handleEventName(item.event_name)] }">
          {{ handleEventName(item.template_name) }}
        </div>
        <div class="itemName" :style="{ color: colorMap[handleEventName(item.event_name)] }">
          {{ item.template_test_subject }} ({{ item.exam_info.exam_type }})
        </div>
        <div class="eventTime">
          <div class="eventTimeContainer">
            <div v-if="item.exam_info" class="eventTimeItem">
              Start Time : [{{ account_continent }} UTC+{{ timezones[account_continent] }}] {{
                formatSessionDate(item.exam_info.exam_begin_time) }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div><el-label v-if="item.exam_info.status.complete_status == true">Completed</el-label></div>
        <div><el-label v-if="item.exam_info.status.complete_status == false">Not Completed</el-label></div>
        <div v-if="item.exam_info" class="eventStatusContainer"
          :style="{ background: colorMap[handleEventName(item.event_name)] }">

          <div class="toContent"
            v-if="item.exam_info.status.status === '进行中' && item.exam_info.status.complete_status == false"
            @click="toExam(item)">
            Test Entry
          </div>
          <div class="toContent"
            v-if="item.exam_info.status.status === '进行中' && item.exam_info.status.complete_status == true && item.exam_info.status.allow_retake == 'NO'"
            @click="showTip(1)">
            Closed for Entry
          </div>
          <div class="toContent"
            v-if="item.exam_info.status.status === '进行中' && item.exam_info.status.complete_status == true && item.exam_info.status.allow_retake == 'YES'"
            @click="showTip(3)">
            Retake the Test
          </div>
          <div class="outContent" v-if="item.exam_info.status.status === '还未开始'">
            <div class="txt">Test Countdown</div>
            <div class="time" :style="{ color: colorMap[handleEventName(item.event_name)] }">
              {{
                item.exam_info.countDownTimes
                  ? item.exam_info.countDownTimes
                  : getFormatDuringTime(0)
              }}
            </div>
          </div>
          <div class="toContent" v-if="item.exam_info.status.status === '已结束'" @click="showTip(2)">
            Closed for Entry
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyZhiboRooms,
  checkZhiboOpen,
  getExerciseTestTemplate,
  checkIsUploadTest,
  checkUserInvigilate,
} from '../../../api/arena'
import {
  getUserId,
  setSessionUrl,
  setSessionName,
  setProjectCode,
  getAccountContinent
} from "../../../utils/store";

import Cookie from "js-cookie";
import { formatDate } from '../../../utils/date'

export default {
  data() {
    return {
      project_code: this.$route.query.project_code,
      project_name: Cookie.get('exam_event_name'),
      event_rooms: [],
      colorMap: {
        Mock: "#11448D",
        Artithon: "#11448D",
        Biothon: "#246EB3",
        Chemithon: "#445CB4",
        Econthon: "#E3A948",
        Geothon: "rgb(4,52,124)",
        Historithon: "rgb(171,9,61)",
        Mathethon: "rgb(9,54,80)",
        Physithon: "rgb(53,110,174)",
        Psychothon: "rgb(111,183,217)",
      },
      account_continent: getAccountContinent(),
      user_id: getUserId(),
      timezones: {
        'Asia': 8,
        'Oceania': 8,
        'Europe': 1,
        'Africa': 1,
        'North America': -4,
        'South America': -4

      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getMyZhiboRooms(getUserId(), this.project_code).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.event_rooms = res.data.data.map((item) => {
            // if (item.status.status === "还未开始") {
            //   this.handleRestTime(item);
            // }

            if (item.exam_info) {
              const currentTime = Math.floor(Date.now() / 1000);

              console.log('Original Start Time:', new Date(item.exam_info.status.start_time * 1000).toLocaleString());
              console.log('Original End Time:', new Date(item.exam_info.status.end_time * 1000).toLocaleString());
              const adjustedStartTime = this.recalculateTimeByContinent(item.exam_info.status.start_time);
              const adjustedEndTime = this.recalculateTimeByContinent(item.exam_info.status.end_time);

              console.log('Adjusted Start Time:', new Date(adjustedStartTime * 1000).toLocaleString());
              console.log('Adjusted End Time:', new Date(adjustedEndTime * 1000).toLocaleString());
              if (currentTime < adjustedStartTime) {
                item.exam_info.status.status = "还未开始";
              } else if (currentTime >= adjustedStartTime && currentTime < adjustedEndTime) {
                item.exam_info.status.status = "进行中";
              } else {
                item.exam_info.status.status = "已结束";
              }
            }
            // item.exam_info.status.complete_status = false
            if (item.exam_info && item.exam_info.status.status === "还未开始") {
              this.handleRestTime(item.exam_info);
            }
            return item;
          });
        }
      });
    },

    toRoom(item) {

      checkZhiboOpen(
        getUserId(),
        item.event_meeting_room_ids[0],
        this.project_code,
        item.event_name
      ).then((res) => {
        if (res.data.code === 0) {
          if (item.huifang && item.huifang == "是") {
            console.log(11111, item);

            setSessionUrl(item.videoUrl);
            setSessionName(item.name);
            //判断是否需要监考
            checkUserInvigilate(this.project_code, getUserId()).then((res) => {
              // console.log(1111,res)
              //true 需要监考
              if (res.data == true) {
                this.$router.push({
                  path: "/wao/lecture-ready",
                  query: {
                    videoTime: item.videoTime,
                    template_id: item.template_id,
                    type: "video",
                  },
                });
              } else {
                let url = this.$router.resolve({
                  path: "wao/videoPlay",
                  query: {
                    videoTime: item.videoTime,
                    template_id: item.template_id,
                  },
                });
                window.open(url.href, "_blank");
              }
            });

            // this.$confirm('Are you sure you want to enter the lecture?', 'Tips', {
            //     confirmButtonText: 'confirm',
            //     cancelButtonText: 'cancel',
            //     type: 'warning'
            // }).then(res=>{
            //     let url = this.$router.resolve({
            //         path:'VideoPlay',
            //         query:{
            //             videoTime:item.videoTime
            //         },
            //     })

            //     window.open(url.href,'_blank')
            // })
          } else {
            // console.log(222)
            this.$confirm(
              "Are you sure you want to enter the lecture?",
              "Tips",
              {
                confirmButtonText: "confirm",
                cancelButtonText: "cancel",
                type: "warning",
              }
            ).then((res) => {
              let url = this.$router.resolve({
                path: "/wao/studentscreen",
                query: {
                  roomId: item.event_meeting_room_ids[0],
                },
              });
              window.open(url.href, "_blank");
            });
          }
        } else if (res.data.code === -1) {
          this.$message.warning("Time not right！");
        } else if (res.data.code === -2) {
          this.$message.warning("No room info");
        } else if (res.data.code === -3) {
          this.$message.warning("Have finished");
        }
      });
    },
    toExam(item) {
      console.log(11111, item);
      // checkTestLinkOpen(getUserId(),item.exam_info.name).then(res=>{
      //     if(res.data.code===0){
      //         this.$confirm('Are you sure you want to start the exam?', 'Tips', {
      //             confirmButtonText: 'confirm',
      //             cancelButtonText: 'cancel',
      //             type: 'warning'
      //         }).then(()=>{
      //             let url = this.$router.resolve({
      //                 path:'/test',
      //                 query:{
      //                     template_id:item.exam_info.test_link,
      //                     roomId:res.data.data
      //                 }
      //             })
      //             window.open(url.href,'_blank')
      //         })
      //     }else if(res.data.code===-1){
      //         this.$message.warning('Time not right!')
      //     }else if(res.data.code===-2){
      //         this.$message.warning('No room Info')
      //     }else if(res.data.code===-3){
      //         this.$message.warning('Have Finished')
      //     }else if(res.data.code===-4){
      //         this.$message.warning('Please enter the room in Proctor Pro APP')
      //     }
      // })
      this.goExam(item);
    },
    recalculateTimeByContinent(time) {//TODO



      //input time is the start time on the server (UTC+8)
      // Convert input time to milliseconds
      const inputTime = time;//new Date(time).getTime();

      // Get user's local timezone offset in minutes
      const localOffset = new Date().getTimezoneOffset();

      // Define base offsets for different regions (in minutes)
      const asiaOceaniaOffset = -480; // UTC+8
      const europeAfricaOffset = -60; // UTC+1
      const americasOffset = +240; // UTC-4

      let adjustedTime;

      if (this.account_continent === 'Asia' || this.account_continent === 'Oceania') {
        // Adjust from Asia/Oceania time to local time
        adjustedTime = inputTime + asiaOceaniaOffset * 60;
      } else if (this.account_continent === 'Europe' || this.account_continent === 'Africa') {
        // Adjust from Europe/Africa time to local time

        adjustedTime = inputTime + europeAfricaOffset * 60;
      } else if (this.account_continent === 'North America' || this.account_continent === 'South America') {
        // Adjust from Americas time to local time
        adjustedTime = inputTime + americasOffset * 60;
      } else {
        // If continent is not specified, assume the input time is already in local time
        adjustedTime = inputTime;
      }

      const formattedDate = new Date(adjustedTime * 1000).toLocaleString();
      console.log('adjustedTime A:', formattedDate);
      return Math.floor(adjustedTime);
    },
    goExam(item) {
      var startTime = item.exam_info.exam_begin_time;
      var adjustedTime = this.recalculateTimeByContinent(startTime);
      var temp1 = parseInt(adjustedTime) * 1000;
      var currentTime = new Date().getTime();
      console.log('item :' + item)
      // console.log('currentTime :' + currentTime)
      //TODO:为了测试，去掉时间判断
      var test_event = item.event_name;


      if (item.exam_info.exam_type == "Formal") {
        if (temp1 - this.exam_entry_before_time > currentTime) {
          this.$message({
            message:
              "The test has not started. You can only enter 15 minutes before the start time",
            type: "warning",
          });
          return;
        }
        //考试开始15分钟，无法进入考场
        if (temp1 + this.exam_prohibit_time_when_start < currentTime) {
          this.$message({
            message:
              "The entry time is already over. You can not enter into the test room now.",
            type: "warning",
          });
          return;
        }
      }

      setProjectCode(this.project_code);

      getExerciseTestTemplate(
        item.event_name,
        this.project_code,
        item.exam_info.exam_type,
        test_event,
        item.exam_info.exam_template_id
      ).then((res) => {
        if (res.data.code == 0) {
          var result = res.data.data;
          var template_id = result._id.$id;
          this.doCheck(template_id, temp1, item.exam_info.exam_type, test_event);
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    doCheck(template_id, timeStr, event_type, test_event) {
      var student_id = getUserId();
      checkIsUploadTest(template_id, student_id).then((res) => {
        var result = res.data;
        // result= false
        if (result) {
          this.$alert(
            "Please note that you have already completed this test. No re-entry is allowed.",
            "Closed for Entry",
            {
              confirmButtonText: "OK",
              callback: () => { },
            }
          );
        } else {
          //判断是否需要监考
          checkUserInvigilate(this.project_code, getUserId()).then((res) => {
            // console.log(1111,res)
            //true 需要监考
            if (res.data == true) {
              this.$router.push({
                path: "/wao/exam-ready",
                query: {
                  template_id: template_id,
                  start_time: timeStr,
                  event_type: event_type,
                },
              });
            } else {
              this.$router.push({
                path: "/wao/exam",
                query: {
                  template_id: template_id,
                  invigilateStatus: true,
                },
              });
            }
          });
        }
      });
    },

    showTip(item) {

      if (item == 1) {
        this.$alert(
          "Please note that you have already completed this test. No re-entry is allowed.",
          "Tip",
          {
            confirmButtonText: "OK",
            callback: () => { },
          }
        );
      }
      if (item == 2) {
        this.$alert(
          "You have missed the entry time for this exam. For further assistance, please contact your instructor.",
          "Test Entry Closed",
          {
            confirmButtonText: "Confirm",
            callback: () => { },
          }
        );
      }
      if (item == 3) {
        this.$confirm(
          "This test allows retaking. By confirming to proceed, you are going to retake the test and your previous answers will be overwritten.",
          "Retake Confirmation",
          {
            confirmButtonText: "Proceed",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        ).then(() => {
          // Add logic here to handle the retake action
          // For example, you might want to call the toExam method or similar
        }).catch(() => {
          // Handle cancellation if needed
        });
      }


    },
    formatSessionDate(time) {
      var time_1 = JSON.parse(time) * 1000;
      var date = new Date(time_1);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    formateTime(data) {
      let item = new Date(data * 1000 - 8 * 3600 * 1000);
      //   let item = new Date(data * 1000);
      //   return item.toUTCString()
      let month = item.getMonth() + 1;
      return (
        item.getDate() +
        " " +
        this.formatMonth(month) +
        " " +
        item.getFullYear() +
        " " +
        this.formatValue(item.getHours()) +
        ":" +
        this.formatValue(item.getMinutes()) +
        ":" +
        this.formatValue(item.getSeconds())
      );
      //   return `${item.getMonth()+1}-${item.getDate()} ${item.getHours()}:${item.getMinutes()>9?item.getMinutes():'0'+item.getMinutes()}`;
    },
    formatMonth(month) {
      switch (month) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sep";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
      }
    },
    formatValue(value) {
      if (value > 9) {
        return value;
      }
      return "0" + value;
    },
    handleEventName(event) {
      return event;
      let event_array = event.split(" ");
      return event_array[0];
    },
    handleIndex(index) {
      return (index % 4) + 1;
    },
    getFormatDuringTime(during) {
      var s = Math.floor(during / 1) % 60;
      s = s < 10 ? '0' + s : s;
      during = Math.floor(during / 60);
      var i = during % 60;
      i = i < 10 ? '0' + i : i;
      during = Math.floor(during / 60);
      var h = during;
      h = h < 10 ? '0' + h : h;
      return h + ":" + i + ":" + s;
    },
    handleRestTime(item) {
      let adjustedTime = this.recalculateTimeByContinent(item.status.start_time);
      let currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      let totalTime = adjustedTime - currentTime;

      item.countDownThread = window.setInterval(() => {
        this.$set(item, "countDownTimes", this.getFormatDuringTime(totalTime));
        totalTime--;
        if (totalTime < 0) {
          window.clearInterval(item.countDownThread);
          item.status.status = "进行中";
        }
        // console.log(this.event_rooms)
      }, 1000);
    },
  },
};
</script>

<style lang="less">
.listContainer {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  .dl {
    width: 84%;
    display: flex;
    align-items: center;
    font-family: Impact;
    justify-content: center;

    color: #072d76;
    font-size: 50px;
    margin-bottom: 20px;
  }

  .eventItem {
    height: 17vh;
    margin-bottom: 4vh;
    position: relative;
    display: flex;
    margin-top: 30px;
    border: 1px solid #072d76;

    .eventImg {
      background-size: cover;
      position: absolute;
      top: 0;
      // z-index: 1;
      height: 100%;
      width: 100%;
      max-width: 100%;
      display: block;
      // background-color:cornflowerblue;
    }

    .eventImg2 {
      background-size: cover;
      position: absolute;
      top: 0;
      // z-index: 1;
      height: 100%;
      width: calc(100% - 600px);
      max-width: calc(100% - 600px);
      display: block;
      // background-color:cornflowerblue;
    }

    .eventImg1 {
      float: right;
      position: absolute;
      top: 0;
      // z-index: 1;
      height: 100%;
      margin-left: calc(100% - 600px);
      width: 600px;
      max-width: 600px;
      display: block;
      // background-color:cornflowerblue;
    }

    .left {
      width: 60%;
      overflow: hidden;
      position: relative;

      .itemTitle {
        margin-top: 2vh;
        font-size: 2.4vh;
        font-family: Gravity-V546;
        font-weight: 400;
        padding-left: 30px;
        white-space: nowrap;
      }

      .itemName {
        font-size: 5vh;
        font-family: Gravity-V546;
        font-weight: 400;
        padding-left: 30px;
        white-space: nowrap;
      }

      .eventTime {
        background: #4880F5;
        height: 2.6vh;
        width: 100%;
        margin-top: 1vh;
        margin-left: 30px;
        color: white;
        font-size: 1.4vh;
        line-height: 2.6vh;
        font-family: Gravity-V546;
        font-weight: bold;

        .eventTimeContainer {
          // background-color: red;
          width: 100%;
          margin: 0 auto;
          display: flex;

          .eventTimeItem {
            width: 50%;
            margin-left: 10px;
            text-align: left;
          }
        }
      }
    }

    .right {
      width: 38%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .eventStatusContainer {
        width: 18vh;
        height: 50%;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #072d76;

        .toContent {
          text-align: center;
          color: white;
          font-family: Gravity-V546;
          font-weight: bold;
          cursor: pointer;
          padding: 3vh;
        }

        .outContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;

          .txt {
            margin-top: 0.4vh;
            font-size: 1.4vh;
            font-family: Gravity-V546;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
          }

          .time {
            background: white;
            width: 80%;
            padding: 0.6vh 4px;
            font-family: Impact;
            font-weight: 400;
            font-size: 3vh;
            text-align: center;
            margin-bottom: 0.4vh;
          }
        }
      }
    }
  }

  .topCrumb {
    margin-left: 30px;
    margin-top: 30px;
    width: 90%;
    height: 40px;
    font-size: 20px;
    // background-color: rebeccapurple;
  }
}
</style>